@font-face {
  font-family: 'brokenRobotFont';
  src: local('brokenRobotFont'), url("./BRBB_Typeface.otf") format('opentype');
}

body {
  margin: 0;
  font-family: brokenRobotFont !important;

  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171432 !important;
  background: #e1449d;
  color: #e1449d;
}

.MuiButtonBase-root {
  border-radius: 0 !important;
}

.MuiToggleButtonGroup-root {
  border-radius: 0 !important;
}

.MuiCircularProgress-colorPrimary {
  color: black !important;
}

/*button.MuiToggleButton-root.Mui-selected.MuiTypography-root {*/
/*  color: black !important;*/
/*}*/

button.MuiToggleButton-root:hover {
  transform: scale(1.07);
  /*transition: 0.05s;*/
}

button.MuiToggleButton-root.Mui-selected {
  background-color: #e1449d !important;
  color: black !important;
  background: 0;
  /*border-color: #000 !important;*/
}

button.MuiToggleButton-root {
  border: 0;
  transition: all .1s ease-in-out;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container2 {
  position: relative;
  text-align: center;
  color: white;
  display: inline-block;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  white-space: nowrap;
  color: black;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}